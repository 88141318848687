.c-nav-link {
  font-size: 14px !important;
  color: black !important;
}
.b-nav-link {
  background-color: greenyellow;
  height: 0px !important;
  padding: 0px;
  font-size: 14px !important;
  color: black !important;
}
#show_bg_2 {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 203, 0, 0.52),
      rgba(117, 19, 93, 0.73)
    ),
    url("http://localhost:3000/static/media/logo_black.a8fd20dd0597e68004be.png");
  width: 100%;
  height: 400px;
  background-size: cover;
  color: white;
  padding: 20px;
}
